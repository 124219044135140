



























import {defineComponent, ref} from '@nuxtjs/composition-api';
import {useTraverseCategory} from "~/modules/catalog/category/helpers/useTraverseCategory";

export default defineComponent({
  props: {
    categories: {
      type: Array,
      default: () => []
    },
    getCatLink: {
      type: Function,
      require: true
    },
    filter: {
      type: [Object, Function],
      default: () => Object
    },
    maxOptionCount: {
      type: Number,
      default() {
        return 5
      }
    }
  },
  data() {
    return {
      showMore: false
    }
  },
  setup(props) {
    const catOptions = ref([]);
    const categories = ref([]);

    const {categoryTree, findActiveCategory} = useTraverseCategory();

    if (props.categories.length > 0) {
      catOptions.value = props.categories;
    } else {
      //@ts-ignore
      catOptions.value = props.filter.options;
    }

    const getCategoryLinkObj = (category) => {
      if (!category?.url_path && !category?.url_suffix) {
        if (category?.label) {
          return findActiveCategory(categoryTree.value, category.label, 'name') || false;
        } else {
          return false;
        }
      } else {
        return category;
      }
    }

    const options = ref({
      less: [],
      more: []
    });

    for (let c = 0; c < catOptions.value.length; c++) {
      const category = getCategoryLinkObj(catOptions.value[c]);

      if (category) {
        categories.value.push(category);
      }
    }

    categories.value.map((option, index) => {
      if (index < props.maxOptionCount) {
        options.value.less.push(option);
      } else {
        options.value.more.push(option);
      }
    })

    return {
      catOptions,
      getCategoryLinkObj,
      options
    }
  }
});
